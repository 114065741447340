@media screen and (max-width: 1100px) {
    .punks-container {
        padding-top: 20px;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: center;
    }

    .remain-card {
        margin-left: 20px;
    }
}